<template>
  <div v-if="props.typeLogo !== 14" class="buy-btn-box">
    <van-button :icon="buyIcon" color="#fff" block @click="sendPay" :disabled="props.product?.amount <= 0">
      {{ props.bizSource === 2 ? '支付押金' : '立即购买' }}
    </van-button>
  </div>
  <van-row v-else gutter="20" class="buy-btn-group">
    <van-col span="12">
      <div class="btn-box">
        <van-button :icon="buyIcon" color="#fff" block @click="sendPay">
          {{ props.bizSource === 2 ? '支付押金' : '立即购买' }}
        </van-button>
      </div>
    </van-col>
    <van-col span="12">
      <div class="btn-box">
        <van-button :icon="receiveIcon" :color="receiveBtnColor" block @click="receive">
          免费领取
        </van-button>
      </div>
      <!-- 可以免费领取时,展示免费领取按钮, 不能领取时展示倒计时 -->
      <template v-if="diffTimer > 0">
        <van-count-down :time="diffTimer" millisecond @finish="timerFinish">
          <template #default="timeData">
            <div class="timer">
              <template v-if="timeData.hours > 0">{{ timeData.hours }}小时</template>
              {{ timeData.minutes }}分{{ timeData.seconds }}秒 后再次领取
            </div>
          </template>
        </van-count-down>
      </template>
    </van-col>
  </van-row>

  <!-- 免费领取弹窗 -->
  <van-overlay v-model:show="showReceiveVisible" width="80vw">
    <div class="wrapper">
      <van-image :src="receiveBgIcon" class="receive-bg-box">
        <div class="qr-code-box">
          <vue-qrcode :value="props.product?.imageBackUrl" width="100%" height="100%" :options="{ margin: 1, errorCorrectionLevel: 'H' }" tag="img" />
        </div>
      </van-image>
      <van-icon name="close" size="40" color="#fff" @click="showReceiveVisible = false"/>
    </div>
  </van-overlay>
</template>

<script>
import { Button, Col, Row, Image as VanImage, Overlay, Icon, showFailToast, showConfirmDialog, CountDown } from 'vant';
import { computed, onMounted, ref } from 'vue';
import buyIcon from '@/assets/buy_icon.png';
import receiveIcon from '@/assets/receive_icon.png';
import receiveBgIcon from '@/assets/receive_bg.png';
import { request } from '@/utils/request';
import router from "@/router";
import { getUrlParam } from '@/utils/auth';
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  components: {
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [VanImage.name]: VanImage,
    [Overlay.name]: Overlay,
    [Icon.name]: Icon,
    [CountDown.name]: CountDown,
    VueQrcode,
  },
  props: {
    bizSource: {
      type: [String, Number],
      default: '',
    },
    product: {
      type: Object,
      default: () => {
      },
    },
    typeLogo: {
      type: [String, Number],
      default: '',
    },
    disableReceiveBtn: {
      type: Boolean,
      default: false,
    },
    num: {
      type: Number,
      default: 0,
    }
  },
  emits: ['buy'],
  setup(props, {emit}) {
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');
    const showBuy = ref(false);
    const loadLastOrder = ref(false);
    const showReceiveVisible = ref(false);
    const receiveMsg = ref('');
    const diffTimer = ref(0);

    onMounted(async () => {
      await getLatestOrder();
    });

    // 免费领取按钮颜色
    const receiveBtnColor = computed(() => {
      if (props.disableReceiveBtn) {
        return 'rgba(0, 0, 0, 0.2)';
      }
      // 上次订单信息未加载完成时,展示灰色按钮
      if (!loadLastOrder.value || receiveMsg.value !== '') {
        return 'rgba(0, 0, 0, 0.2)';
      }
      return '#fff';
    });

    // 加载用户最近一次订单信息
    const getLatestOrder = async () => {
      await request({
        url: '/h5/getLatestOrder',
        method: 'get',
        params: {productId: props.product.productId, deviceNo}
      }).then((res) => {
        console.log(res);
        loadLastOrder.value = true;
        if (res.success === true) {
          const lastOrderCreatedAt = res.result?.createdAt || '';
          // 获取当前时间的时间戳
          const currentTimestamp = Date.now();
          // 计算一个小时之前的时间戳（单位：毫秒）
          const oneHourAgoTimestamp = currentTimestamp - (60 * 60 * 1000);
          // 判断给定的时间戳是否在一个小时前
          if (lastOrderCreatedAt && lastOrderCreatedAt > oneHourAgoTimestamp) {
            diffTimer.value = lastOrderCreatedAt - oneHourAgoTimestamp;
            receiveMsg.value = '您已领取过其他商品，请稍后再来';
            return;
          }
          diffTimer.value = 0;
          receiveMsg.value = '';
        } else {
          receiveMsg.value = '您已领取过该商品，请勿重复领取';
        }
      }).catch(({response}) => {
        const {data: {message}} = response;
        // showFailToast('请求出错，请稍后再试！');
        router.push({name: 'error', query: {error: message}})
      });
    };

    // 发起支付
    const sendPay = () => {
      emit('buy');
    }

    // 免费领取
    const receive = () => {
      if (!loadLastOrder.value) {
        showFailToast('数据加载中，请稍后再试!');
        return false;
      }
      if (receiveMsg.value !== '') {
        showFailToast(receiveMsg.value);
        return false;
      }
      if (props.num !== 1) {
        showFailToast('只能领取一个商品!');
        return false;
      }
      if (props.disableReceiveBtn) {
        showFailToast('只能领取一个商品!');
        return false;
      }

      showReceiveVisible.value = true;
      // setTimeout(() => {
      //   showConfirmDialog({message: '跳转到扫码页'}).then(() => {
      //     router.push({name: 'scanReceiveProduct', params: {productId: props.product.productId}})
      //   }).catch(() => {
      //   });
      // }, 1500);
    }

    // 倒计时结束后处理
    const timerFinish = () => {
      window.location.reload();
    }

    return {
      props,
      buyIcon,
      receiveIcon,
      receiveBgIcon,
      showReceiveVisible,
      showBuy,
      loadLastOrder,
      receiveBtnColor,
      diffTimer,

      sendPay,
      timerFinish,
      receive,
    }
  }
}
</script>

<style scoped lang="less">
.buy-btn-box {
  width: 90%;
  margin: 0 auto;
  border: 1px solid #858487;
  border-radius: 10px;

  .van-button {
    color: #5755B3 !important;
    font-size: 1.5rem;
    border-radius: 10px;
    font-weight: 600;
    height: 3rem;
  }
}

.buy-btn-group {
  width: 90%;
  min-height: 11vh;
  margin: 0 auto;

  .btn-box {
    border: 1px solid #858487;
    border-radius: 12px;
    overflow: hidden;

    .van-button {
      color: #5755B3 !important;
      font-size: 1.2rem;
      border-radius: 10px;
      font-weight: 600;
      height: 2.8rem;
    }
  }

  .timer {
    text-align: center;
    font-size: 0.7rem;
    color: red;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  .receive-bg-box {
    width: 33vh;
    height: 66vh;
    margin-top: 10vh;
    position: relative;

    .qr-code-box {
      position: absolute;
      left: 3.3vh;
      top: 20.9vh;
      width: 25.5vh;
      height: 25.5vh;
    }
  }
}
</style>
