<template>
  <div class="container">
    <div class="head-title">
      <span class="van-ellipsis device-no">设备编号：{{ deviceNo }}</span>
      <span class="van-ellipsis" v-if="contactNumber">
        联系电话：<a class="phone" :href="`tel:${contactNumber}`">{{ contactNumber }}</a>
      </span>
    </div>

    <!-- 轮播图 -->
    <div v-if="brands.length !== 0" class="brand-container">
      <swiper
          ref="swiper"
          class="swiper"
          :space-between="30"
          :centered-slides="true"
          style="width: 100%;height: 100%;"
          :navigation="true"
          :modules="modules"
          :autoplay="{ delay: 2500, disableOnInteraction: false }"
          @slideChange="onSlideChange"
      >
        <swiper-slide class="slide" v-for="brand in brands" :key="brand.id">
          <img v-if="brand.type === 1" :src="brand.content" width="100%" height="100%"  alt=""/>
          <video v-else ref="videoRef"  :src="brand.content" width="100%" height="100%" controls @play="onPlay"
                 @pause="onPause"></video>
        </swiper-slide>
      </swiper>
    </div>

    <!-- 产品列表 -->
    <van-list v-model:loading="loading" :finished="finished" finished-text="我是有底线的" class="content-container">
      <van-row gutter="10">
        <template v-if="productList.length !== 0">
          <van-col v-for="product in productList" :key="product.productId" span="6" >
            <div class="good-item" v-show="typeLogo != 15 || product.name == 1">
              <router-link :to="{ name: 'goodsDetail', params: { productId: product.productId }, query: { deviceNo } }">
                <div class="good-img">
                  <van-image
                    width="100%"
                    height="100%"
                    fit="cover"
                    position="cover"
                    :src="product.imageUrl"
                  />
                  <div class="good-stock">库存：{{ product.amount }}</div>
                  <div v-if="product.amount <= 0" class="no-stock">
                    <img src="../../assets/no_stock.png" alt="">
                  </div>
                </div>
                <div class="van-multi-ellipsis--l2 good-name" v-html="product.productName"></div>
                <div class="van-ellipsis good-unit">{{ product.quantity }} {{ product.unit }}</div>
                <div v-if="bizSource != 2" class="van-ellipsis good-price">&yen;{{ amountFormat(product.price) }}元/{{ product.unit }}</div>
              </router-link>
            </div>
          </van-col>
        </template>
        <template v-if="productList.length === 0">
          <van-col :span="24">
            <van-empty description="没有产品" />
          </van-col>
        </template>
      </van-row>
    </van-list>
    <!-- 广告 -->
    <div class="ad-container">
      <div id="goods-ad"></div>
    </div>
  </div>
</template>

<script>
import { Col, Empty, List, Image as VanImage, Row, Swipe, SwipeItem, Cell } from 'vant';
import { onMounted, ref } from 'vue';
import { request } from '@/utils/request';
import { getUrlParam } from '@/utils/auth';
import { amountFormat } from '@/utils/util';
import router from "@/router";
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css'

export default {
  components: {
    [Cell.name]: Cell,
    [Col.name]: Col,
    [Empty.name]: Empty,
    [List .name]: List,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const swiper = ref(null);
    const videoRef = ref(null);
    const productList = ref([]);
    const brands = ref([]);
    const finished = ref(false);
    const loading = ref(false);
    const contactNumber = ref('');
    const bizSource = ref('');
    const typeLogo = ref('');
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');

    // 轮播图是否播放
    const modules = [Autoplay];

    onMounted(() => {
      // 加载商品列表
      onLoad();
      // 加载运营商信息
      getBizInfo();
      // 加载轮播图广告
      getH5Ads();
      // 加载设备类型
      onDeviceInfo()
      // 加载广告
      getTencentGDT();
    });

    const onLoad = () => {
      loading.value = true;
      request({
        url: '/h5/device/getAllProduct',
        method: 'get',
        params: { deviceNo },
      }).then((res) => {
        console.log(res);
        if (res.success === true) {
          productList.value = res.result;

          // 数据全部加载完成
          finished.value = true;
          // 加载状态结束
          loading.value = false;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        // showFailToast('请求出错，请稍后再试！');
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const getBizInfo = () => {
      request({
        url: '/h5/getBizInfo',
        method: 'get',
        params: { deviceNo },
      }).then((res) => {
        if (res.success === true) {
          contactNumber.value = res.result?.serviceTel || '';
          bizSource.value = res.result?.source || '';
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        // showFailToast('请求出错，请稍后再试！');
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const getH5Ads = () => {
      request({
        url: '/h5/getH5Ads',
        method: 'get',
        params: { deviceNo },
      }).then((res) => {
        if (res.success === true) {
          brands.value = res.result;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        // showFailToast('请求出错，请稍后再试！');
        router.push({ name: 'error', query: { error: message }})
      })
    };

    const onDeviceInfo = () => {
      request({
        url: '/h5/device/getDeviceInfo',
        method: 'get',
        params: { deviceNo }
      }).then((res) => {
        if (res.success === true && res.result) {
          typeLogo.value = res.result.typeLogo;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const onPlay = () => {
      console.log('on play');
      swiper.value.$el.swiper.autoplay.pause();
    }

    const onPause = () => {
      swiper.value.$el.swiper.autoplay.run();
    }

    // 轮播图变化时触发，暂停视频播放
    const onSlideChange = () => {
      videoRef.value.forEach((item) => {
        item.pause();
      });
    }

    const getTencentGDT = () => {
      // 广告示例声明
      TencentGDT.push({
        app_id: '1206897647',
        placement_id: '6019843590959528', // 广告位id，请从联盟平台广告位获取
        type: "native", // 广告类型
        display_type: "banner", // 广告播放类型: banner广告
        carousel: 6000, // 轮播时间，> 6000数字类型，否则轮播会被取消
        containerid: "goods-ad", // 广告容器
        onComplete: function (res) {
          console.log("横幅广告", res);
          if (res.ret == 0) {
            console.log("广告播放成功");
          } else {
            console.log("广告播放失败");
          }
        },
      });
    }

    return {
      brands,
      productList,
      loading,
      finished,
      deviceNo,
      contactNumber,
      bizSource,
      modules,
      swiper,
      typeLogo,
      videoRef,

      amountFormat,
      onPlay,
      onPause,
      onSlideChange,
    };
  },
}
</script>

<style lang="less" scoped>
.container {
  padding: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F4F4F4;
  .head-title {
    display: flex;
    height: 2.5rem;
    padding: 0 5px;
    line-height: 2.5rem;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px 1px #d9d9d9;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    font-size: 0.9rem;
    .device-no {
      margin-right: 10px;
    }
    .phone {
      color: #000;
    }
  }
  .brand-container {
    margin-bottom: 10px;
    width: 100%;
    height: 200px;
  }
  .ad-container {
    width: 100%;
    height: 80px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .content-container {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
  }
  .good-item {
    margin-bottom: 10px;
    font-size: .8rem;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 5px 3px 5px 1px #d9d9d9;
    .good-img {
      border-radius: 5px;
      overflow: hidden;
      width: 100%;
      height: 80px;
      position: relative;
      z-index: 0;
      .good-stock {
        font-size: 10px;
        position: absolute;
        right: 0;
        top: 0;
        background-color: #5755B3;
        color: #fff;
        padding: 2px;
        border-bottom-left-radius: 5px;
      }
      .no-stock {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, .5);
      }
    }
    .good-name {
      color: #000;
      height: 2rem;
    }
    .good-unit {
      color: darkgray;
      height: 1rem;
    }
    .good-price {
      height: 1rem;
      color: #f5222d;
    }
  }
}
</style>
