<template>
  <div class="container" :style="{ backgroundColor: startScanVisible ? '#000': '#FDEEFF' }">
    <template v-if="!startScanVisible">
      <van-image round width="80vw" height="80vw" :src="scanImg"/>
      <div class="btn-text">点击下方按钮，扫码机身二维码</div>
      <div class="scan-btn" @click="startScanQrcode">扫码免费领取</div>
    </template>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { Image as VanImage, showFailToast, showLoadingToast, showSuccessToast } from 'vant';
import wx from 'weixin-js-sdk';

import scanImg from "@/assets/scan.png";
import { useRoute } from "vue-router";
import { request } from "@/utils/request";
import router from "@/router";

export default {
  components: {
    [VanImage.name]: VanImage,
  },
  setup() {
    // 接受请求参数
    const route = useRoute();
    const {params: {productId}} = route;

    onMounted(() => {
      // showLoadingToast({ message: '模拟用户扫描中....', onClose: () => handleScanResult([{ rawValue: 'https://api.test.aipszn.cn/api/h5/scan?deviceNo=HKMBAKX' }]) });
    });

    const startScanQrcode = () => {
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf('micromessenger') > -1) {
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            handleScanResult(res.resultStr);
          }
        });
      } else if (ua.indexOf('alipay') > -1) {
        ap.scan(function(res){
          handleScanResult(res.code);
        });
      } else {
        showFailToast('请使用微信或支付宝支付！');
      }
    };

    // 处理扫描的结果
    const handleScanResult = async (text) => {
      // 判断是不是url
      if (!text.startsWith('http') || !text.startsWith('https')) {
        showFailToast('未扫描到数据');
        return;
      }
      // 从url中获取设备编号
      const url = new URL(text);
      console.log(url);
      const urlDeviceNo = url.searchParams.get('deviceNo');
      if (!urlDeviceNo) {
        showFailToast('请扫描机身二维码');
        return;
      }

      showLoadingToast({message: '数据查询中...', forbidClick: true, duration: 0});
      // 获取改设备上的产品
      const goods = await loadDeviceGoods(urlDeviceNo);
      // 判断当前链接上的产品id是否在当前机器上
      if (goods && goods.length > 0) {
        checkProductInDevice(goods, urlDeviceNo);
      } else {
        showFailToast('该设备中不包含该商品, 请联系客服人员');
      }
    };

    const checkProductInDevice = (goods, deviceNo) => {
      const good = goods.find((item) => item.productId == productId);
      if (!good) {
        showFailToast('该设备中不包含该商品, 请联系客服人员');
        return;
      }
      // 判断库存
      const amount = good?.amount || 0;
      if (amount <= 0) {
        showFailToast('该设备中商品库存不足, 请联系客服人员');
        return;
      }
      showSuccessToast({
        message: '查询成功',
        onClose: () => {
          localStorage.setItem('canFreeGet', true);
          router.push({name: 'goodsDetail', params: {productId: productId}, query: {deviceNo}})
        }
      });
    }

    const loadDeviceGoods = async (deviceNo) => {
      return await request({
        url: '/h5/device/getAllProduct',
        method: 'get',
        params: {deviceNo},
      }).then((res) => {
        console.log(res);
        if (res.success === true) {
          return res.result || [];
        }
        return [];
      }).catch(({response}) => {
        const {data: {message}} = response;
        // showFailToast('请求出错，请稍后再试！');
        router.push({name: 'error', query: {error: message}})
      });
    };

    return {
      scanImg,

      startScanQrcode,
      handleScanResult,
    };
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  padding-bottom: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .btn-text {
    margin-top: 0.7rem;
    font-size: 1rem;
    color: #410F8E;
    font-weight: 500;
  }

  .scan-btn {
    margin-top: 0.5rem;
    border-radius: 1.2rem;
    font-size: 1.5rem;
    background-color: #410F8E;
    padding: 0.7rem 2.7rem;
    color: #ffffff;
  }
}
</style>
